import { format } from "date-fns";

export const FS_TOKEN = {
  address: null,
  decimals: 18,
  name: "FantomStarter",
  symbol: "FS",
  logoURL: null,
} as any;

export const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
export const formatDate = (date: Date, toFormat = "LLL d, yyy, HH:mm") => {
  return format(date, toFormat);
};

export const timeLeftInUnits = (endTime: number) => {
  const timeLeft = endTime - Date.now();
  const daysLeft = Math.floor(timeLeft / DAY_IN_MILLIS);
  if (daysLeft < 0) {
    return [0, 0, 0];
  }
  const hoursLeft = Math.floor(
    (timeLeft - daysLeft * DAY_IN_MILLIS) / (60 * 60 * 1000)
  );
  if (hoursLeft < 0) {
    return [daysLeft, 0, 0];
  }
  const minutesLeft = Math.floor(
    (timeLeft - daysLeft * DAY_IN_MILLIS - hoursLeft * 60 * 60 * 1000) /
      (60 * 1000)
  );
  return [daysLeft, hoursLeft, minutesLeft];
};

export const displayTimeLeft = (timeLeftUnits: number[]) => {
  if (timeLeftUnits[0] > 0) {
    return `${timeLeftUnits[0]} day${timeLeftUnits[0] > 1 ? "s" : ""}`;
  }
  if (timeLeftUnits[1] > 0) {
    return `${timeLeftUnits[1]} hour${timeLeftUnits[1] > 1 ? "s" : ""}`;
  }
  if (timeLeftUnits[2] > 0) {
    return `${timeLeftUnits[2]} minute${timeLeftUnits[2] > 1 ? "s" : ""}`;
  }
  return "Closed";
};

export const cleanName = (name: string): string => {
  const index = name.indexOf("--");

  if (index !== -1) {
    return name.substring(0, index).trim();
  }

  return name.trim();
};
